import { Options } from 'options';
import { useMembership } from 'services';
import { VATCategory } from './types';

const rateMape = window.__RUNTIME_CONFIG__?.VAT_RATES || {};
const reducedRates = [5];

export function useAgencyVATRate(): number {
  const { country_code } = useMembership();
  if (country_code in rateMape) {
    return rateMape[country_code];
  }
  // FIXME: We want something for standart category
  return 21;
}

export function useVatCategories(): Record<VATCategory, number> {
  const standardRate = useAgencyVATRate();
  return {
    S: standardRate,
    O: 0,
    AE: 0,
  };
}

export function useExpenseVATOptions(): Options<number> {
  /*
    standard rates with
    agency rate first
    reduced rates
  */
  const agencyRate = useAgencyVATRate();

  const rates: number[] = [agencyRate];
  Object.values(window.__RUNTIME_CONFIG__?.VAT_RATES || {}).forEach((value) => {
    if (!rates.includes(value)) {
      rates.push(value);
    }
  });
  reducedRates.forEach((value) => {
    if (!rates.includes(value)) {
      rates.push(value);
    }
  });

  return rates.map((rate) => ({
    value: rate,
    label: `${rate}%`,
  }));
}
