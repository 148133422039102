import { CheckField, Submit, TextField } from '@ff-it/form';
import type { ReactElement } from 'react';
import type { LinkerContext } from '../types';
import { Preview } from './Preview';
import { MonthSums } from './MonthSums';
import { useBuilderContext } from './useBuilderBlocks';
import { useBuilderState } from './useBuilderState';
import { TemplateForm } from './TemplateForm';
import { Alert, Box } from '@ff-it/ui';
import { InvoiceFields } from '../InvoiceFields';
import { LinkerFields } from '../LinkerFields';
import { ImplicitCompensation } from 'modules/applicator/ImplicitCompensation';
import { IncomeClientPONumberField } from 'routes/Agency/Invoices/Income/IncomeClientPONumberField';

export function CampaignBuilder(linkerContext: LinkerContext): ReactElement {
  // group linkable into blocks
  const builderContext = useBuilderContext(linkerContext.linkable);
  // selection and template handling
  const state = useBuilderState(linkerContext, builderContext.blocks);

  if (builderContext.blocks.length === 0) {
    return (
      <div className="container px-0 ml-0 mt-2">
        <Alert variant="info">Nothing to invoice at the moment</Alert>
      </div>
    );
  }

  return (
    <div data-testid="campaign-builder">
      <div className="container-fluid px-0 ml-0 mt-2">
        <InvoiceFields />
        <div className="form-row">
          <TextField
            name="campaign_title"
            placeholder="Campaign title"
            className="col-md-2"
            size="sm"
            help="Leave empty to use linked"
          />
          <IncomeClientPONumberField size="sm" placeholder="PO number" />
          <TextField name="comment" placeholder="Comment" className="col-md-2" size="sm" autosize multiline />
          <div className="form-group col ml-3">
            <CheckField name="hide_client_po_number" fieldLabel="Hide PO number" variant="switch" />
          </div>
        </div>
        <LinkerFields className="col-6 px-0" />
        <MonthSums entities={linkerContext.entities} builderContext={builderContext} state={state} />
        <TemplateForm template={state.template} setTemplate={state.setTemplate} />
      </div>
      <div className="container px-0 ml-0">
        <Preview />
        <Box display="flex" marginBottom="lg">
          <Submit />
          <ImplicitCompensation />
        </Box>
      </div>
    </div>
  );
}
