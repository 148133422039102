import { Card } from 'components/Card';
import type { Expense } from 'modules/invoicing/expenses/types';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useSessionScope } from 'services';

interface ImportedBlocksProps {
  expense: Expense;
}

export default function ImportedBlocks({ expense }: ImportedBlocksProps): ReactNode {
  if (typeof expense.block_codes === 'undefined' || expense.block_codes === null || expense.block_codes.length === 0) {
    return null;
  }

  return (
    <Card title="Imported blocks" testId="imported-blocks">
      <table className="form-table table mb-0">
        <tbody>
          {expense.block_codes.map((code) => (
            <tr key={code}>
              <td>
                <MatchingBlockWidget expense={expense} code={code} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}

interface MatchingBlockWidgetProps {
  expense: Expense;
  code: string;
}

function MatchingBlockWidget({ expense, code }: MatchingBlockWidgetProps): ReactNode {
  const { slug } = useSessionScope();
  const matchingBlocks = (expense.block_code_matches || []).filter((match) => match.code === code);
  if (matchingBlocks.length === 1) {
    const block = matchingBlocks[0];
    return (
      <Link to={`/${slug}/planning/campaigns/${block.campaign_id}/planning/${block.id}`} target="_blank">
        {code}
      </Link>
    );
  }

  return code;
}
