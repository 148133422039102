import { SetStateAction, useEffect, useState } from 'react';
import type { BuilderBlock, BuilderState, Selection, Template } from './types';
import { removeItemAtIndex } from 'utilities';
import { buildInvoice } from './template';
import { useForm } from 'react-final-form';
import { LinkerContext } from '../types';

function toggleSelection(selection: Selection, blockId: string, month: string): Selection {
  const { [blockId]: selectedBlock = [], ...rest } = selection;

  const monthIndex = selectedBlock.indexOf(month);

  if (monthIndex !== -1) {
    // deselect
    const block = removeItemAtIndex(selectedBlock, monthIndex);
    if (block.length > 0) {
      return {
        ...rest,
        [blockId]: block,
      };
    } else {
      return rest;
    }
  } else {
    // select
    const block = [...selectedBlock, month];

    return {
      ...selection,
      [blockId]: block,
    };
  }
}

const createSelection = (blocks: BuilderBlock[]): Selection =>
  blocks.reduce((acc: any, block) => {
    const months = Object.keys(block.months).filter((m) => Object.keys(block.months[m].amounts).length > 0);
    if (months.length > 0) {
      acc[block.id] = months;
    }
    return acc;
  }, {});

export function useBuilderState(linkerContext: LinkerContext, blocks: BuilderBlock[]): BuilderState {
  const form = useForm();

  const [state, setState] = useState<{
    selection: Selection;
    template: Template;
  }>(() => {
    const selection = createSelection(blocks);

    return {
      selection: selection,
      template: {
        facet: 'BLOCK',
        expandFees: false,
        appendComment: false,
        altActivityName: false,
        transparentUnits: false,
        appendPONumbers: false,
      },
    };
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: blocks are derived from context
  useEffect(() => {
    // when finance period changes we reselect
    setState((state) => ({
      ...state,
      selection: createSelection(blocks),
    }));
  }, [linkerContext]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: form is stable
  useEffect(() => {
    const { bound, rows, date_from, date_to } = buildInvoice(
      linkerContext,
      blocks,
      state.selection,
      state.template,
      form.getFieldState('rows')?.value || [],
    );

    // @FIXME initial render
    setTimeout(() => {
      form.batch(() => {
        form.change('bound', bound);
        form.change('rows', rows);
        form.change('date_from', date_from);
        form.change('date_to', date_to);
        form.change('_template', state.template);
      });
    });
  }, [state]);

  function select(blockId: string, month: string): void {
    setState(({ selection, template }) => {
      return {
        selection: toggleSelection(selection, blockId, month),
        template,
      };
    });
  }

  function setTemplate(action: SetStateAction<Template>): void {
    setState(({ selection, template }) => {
      return {
        selection,
        template: typeof action === 'function' ? action(template) : action,
      };
    });
  }

  const clear =
    Object.keys(state.selection).length > 0
      ? () => {
          setState(({ template }) => ({
            selection: {},
            template,
          }));
        }
      : undefined;

  return {
    ...state,
    select,
    setTemplate,
    clear,
  };
}
