import {
  Badge,
  useEntity,
  EntityActions,
  Icon,
  TabNav,
  DownloadButton,
  ActionDropdown,
  useDownloadHandler,
} from 'components';
import { InvoiceSource, PaymentStateBadge, useNavLinks } from 'modules/invoicing/common';
import permissions from 'modules/invoicing/income/permissions';
import { bookingActions } from 'modules/invoicing/income';
import { Button } from '@ff-it/ui';
import { ReactElement } from 'react';
import { Income } from 'modules/invoicing/income/types';

export function Nav(): ReactElement {
  const { item } = useEntity<Income>();
  const nav = useNavLinks(item, permissions);
  const {
    id,
    state,
    payer,
    number,
    sums: { paym_state },
    token,
  } = item;

  return (
    <div className="container-fluid header header--income">
      <div className="d-flex flex-row align-items-center my-2">
        <h2 className="mt-0">
          <InvoiceSource {...item} className="mr-1" />
          <strong>{payer.title}</strong>
          <small className="text-muted ml-2">
            #{number} <PaymentStateBadge state={paym_state} />
          </small>
        </h2>
        <Badge state={state} className="ml-2" />
        {state !== 'CANCELED' && (
          <div className="btn-toolbar ml-auto">
            <EntityActions actions={bookingActions} />
            {state === 'DRAFT' ? (
              <Button
                variant="outline-primary"
                className="ml-1"
                href={`/api/v1/i/${token}/`}
                target="_blank"
                component={'a'}
                size="sm"
              >
                Invoice <Icon icon="file-pdf" className="ml-1" />
              </Button>
            ) : (
              <DownloadButton
                url={`invoicing/income/${id}.pdf`}
                variant="outline-primary"
                icon="file-pdf"
                className="ml-1"
                size="sm"
              >
                Invoice
              </DownloadButton>
            )}
            <ActionDropdown
              className="ml-1"
              variant="outline-primary"
              size="sm"
              icon="ellipsis-vertical"
              actions={[
                {
                  action: 'acceptance',
                  button: {
                    icon: 'file-signature',
                    children: 'Acceptance certificate',
                    onClick: useDownloadHandler({ url: `invoicing/income/${id}/acceptance.docx` }),
                  },
                },
                {
                  action: 'peppol',
                  button: {
                    icon: 'file-xml',
                    children: 'Peppol BIS3 XML',
                    onClick: useDownloadHandler({ url: `invoicing/income/${id}/peppol.xml` }),
                  },
                },
              ]}
            />
          </div>
        )}
      </div>
      <TabNav nav={nav} />
    </div>
  );
}
